import React, { type FC, useState, type ReactElement, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { getBanks, getCards, getWallets } from '../../redux/reducers/withdrawalMethods';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { AddBankFormComponent } from '../../pages/Accounts/WithdrawalSection/AddBankFormComponent/AddBankFormComponent';
import { AddCardFormComponent } from '../../pages/Accounts/WithdrawalSection/AddCardFormComponent/AddCardFormComponent';
import { AddCryptoFormComponent } from '../../pages/Accounts/WithdrawalSection/AddCryptoFormComponent/AddCryptoFormComponent';
import { AddWithdrawalMethodSection } from '../../pages/Accounts/WithdrawalSection/AddWithdrawalMethodSection/AddWithdrawalMethodSection';
import { WithdrawalHeaderComponent } from '../../pages/Accounts/WithdrawalSection/WithdrawalHeaderComponent/WithdrawalHeaderComponent';
import { type TypeSubHeaderWithdrawalsOptions } from '../../pages/Accounts/WithdrawalSection/WithdrawalComponent';
import { SavedBankWithdrawalOptions } from '../../pages/Accounts/WithdrawalSection/BankWithdrawalSection/SavedBankWithdrawalOptions/SavedBankWithdrawalOptions';
import { SavedCardWithdrawalOptions } from '../../pages/Accounts/WithdrawalSection/CardWithdrawalSection/SavedCardWithdrawalOptions/SavedCardWithdrawalOptions';
import { SavedCryptoWithdrawalOptions } from '../../pages/Accounts/WithdrawalSection/CryptoWithdrawalSection/SavedCryptoWithdrawalOptions/SavedCryptoWithdrawalOptions';
import { ButtonComponent } from '../../components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setShowFooter } from '../../redux/reducers/showFooter';
import { type WithdrawalType } from '../../redux/reducers/withdrawalTypes';
import { useGetWithdrawalTypesQuery } from '../../redux/api/withdrawalTypesApi';

import style from './WithdrawalMethodsComponent.module.css';
import { type IAccount } from '../../redux/reducers/accounts';

interface InterfaceWithdrawalMethodsComponent {
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

const WithdrawalAddMethodsNodes = {
  Card: AddCardFormComponent,
  Bank: AddBankFormComponent,
  Crypto: AddCryptoFormComponent
}

type OptionsType = Array<{ ticker: TypeSubHeaderWithdrawalsOptions }>

export const WithdrawalMethodsComponent: FC<InterfaceWithdrawalMethodsComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [selectedAsset, setSelectedAsset] = useState<TypeSubHeaderWithdrawalsOptions | null>(null);
  const [addWithdrawalMethod, setAddWithdrawalMethod] = useState(false);
  const [withdrawalOptions, setWithdrawalOptions] = useState<OptionsType>([]);
  const [bankSelect, setBankSelect] = useState<string>('');
  const [cardSelect, setCardSelect] = useState<string>('');
  const [cryptoSelect, setCryptoSelect] = useState<string>('');
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;

  const {
    data: withdrawalData,
    error: withdrawalError,
    isLoading: isWithdrawalLoading
  } = useGetWithdrawalTypesQuery({});

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  const { _id: userId } = useAppSelector((state) => state.user);
  const { wallets, cards, banks } = useAppSelector((state) => state.withdrawalMethods);

  useEffect(() => {
    if (userId === undefined) return;

    Promise.allSettled([
      dispatch(getBanks(userId)),
      dispatch(getWallets(userId)),
      dispatch(getCards(userId))
    ])
  }, [userId]);

  useEffect(() => {
    const isDataLoadedSuccess = withdrawalData !== undefined && withdrawalData.success === true;
    const isWithdrawalSuccessful = withdrawalError === undefined;

    if (isDataLoadedSuccess && isWithdrawalSuccessful && !isWithdrawalLoading) {
      const options: OptionsType = withdrawalData.data
        .map(({ name }: WithdrawalType) => ({ ticker: name }));

      setWithdrawalOptions(options)
    }
  }, [withdrawalData, withdrawalError, isWithdrawalLoading]);

  const renderSelectedFormComponent = (): ReactElement | null => {
    if (selectedAsset !== null) {
      const Component = WithdrawalAddMethodsNodes[selectedAsset];

      const props = { setAddWithdrawalMethod };

      return <Component {...props} />;
    } else {
      return null
    }
  }

  const handleAddWithdrawalMethod = (type: string): void => {
    setAddWithdrawalMethod(true);
    setSelectedAsset(type as TypeSubHeaderWithdrawalsOptions);
  }

  return (
    <div className={ style.mainWrapper }>
      { addWithdrawalMethod
        ? <AddWithdrawalMethodSection setOpen={ setOpen } setAddWithdrawalMethod={ setAddWithdrawalMethod } selectedAccount={selectedAccount}>
            { renderSelectedFormComponent() }
          </AddWithdrawalMethodSection >
        : <>
        <WithdrawalHeaderComponent setOpen={ setOpen } title='Withdrawal Metods' />
          <div className={ style.bodywrapper }>
            {
              withdrawalOptions.some(({ ticker }) => ticker === 'Card') && (
                <div className={ style.containerOptions }>
                <div className={ style.optionsRow }>
                  <p className={ style.containerOptionsText }>Cards</p>
                  <div className={ style.btnContainer }>
                    <ButtonComponent
                      onClick={() => { handleAddWithdrawalMethod('Card'); } }
                      variant='outlined'
                      customInlineStyles={{ border: 'none', padding: '8px' }}
                      id='Card'
                    >
                      <AddIcon style={{ color: 'var(--CFD-theme-System-Tertiary)' }}/>
                      Add Card
                    </ButtonComponent>
                  </div>
                </div>
              { cards.length > 0
                ? <div className={ style.savedCardsWrapper }>
                    <p className={ style.savedCardsText }>Saved Cards</p>
                    <SavedCardWithdrawalOptions selectedOptions={ cardSelect } setSelectedOptions={ setCardSelect } options={ cards }/>
                  </div>
                : null
              }
              </div>
              )
            }
            {
              withdrawalOptions.some(({ ticker }) => ticker === 'Bank') && (
                <div className={ `${style.containerOptions} ${style.borderTopBottom}` }>
                <div className={ style.optionsRow }>
                  <p className={ style.containerOptionsText }>Banks</p>
                  <div className={ style.btnContainer }>
                    <ButtonComponent
                      onClick={() => { handleAddWithdrawalMethod('Bank'); } }
                      variant='outlined'
                      customInlineStyles={{ border: 'none', padding: '8px' }}
                      id='Bank'
                    >
                      <AddIcon style={{ color: 'var(--CFD-theme-System-Tertiary)' }}/>
                      Add Bank
                    </ButtonComponent>
                  </div>
                </div>
                { banks.length > 0
                  ? <div className={ style.savedCardsWrapper }>
                      <p className={ style.savedCardsText }>Saved Banks</p>
                      <SavedBankWithdrawalOptions options={ banks } selectedOptions={ bankSelect } setSelectedOptions={ setBankSelect } />
                    </div>
                  : null
                }
              </div>
              )
            }
            {
              withdrawalOptions.some(({ ticker }) => ticker === 'Crypto') && (
                <div className={ style.containerOptions }>
                <div className={ style.optionsRow }>
                  <p className={ style.containerOptionsText }>Crypto Addresses</p>
                  <div className={ style.btnContainer }>
                    <ButtonComponent
                      onClick={() => { handleAddWithdrawalMethod('Crypto'); } }
                      variant='outlined'
                      customInlineStyles={{ border: 'none', padding: '8px' }}
                      id='Crypto'
                    >
                      <AddIcon style={{ color: 'var(--CFD-theme-System-Tertiary)' }}/>
                      Add Crypto Address
                    </ButtonComponent>
                  </div>
                </div>
                { wallets.length > 0
                  ? <div className={ style.savedCardsWrapper }>
                      <p className={ style.savedCardsText }>Saved Crypto Addresses</p>
                      <SavedCryptoWithdrawalOptions selectedOptions={ cryptoSelect } setSelectedOptions={ setCryptoSelect } options={ wallets }/>
                    </div>
                  : null
                }
              </div>
              )
            }
          </div>
        </>}
    </div>
  )
}
