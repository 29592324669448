import React, { type FC, useState, type ReactElement, useEffect } from 'react';
import { t } from 'i18next';

import { WithdrawalHeaderComponent } from './WithdrawalHeaderComponent/WithdrawalHeaderComponent';
import { WithdrawalSubHeaderComponent } from './WithdrawalSubHeaderComponent/WithdrawalSubHeaderComponent';
import { CardWithdrawSection } from './CardWithdrawalSection/CardWithdrawalSection';
import { AddWithdrawalMethodSection } from './AddWithdrawalMethodSection/AddWithdrawalMethodSection';
import { AddCardFormComponent } from './AddCardFormComponent/AddCardFormComponent';
import { BankWithdrawSection } from './BankWithdrawalSection/BankWithdrawalSection';
import { AddBankFormComponent } from './AddBankFormComponent/AddBankFormComponent';
import { CryptoWithdrawSection } from './CryptoWithdrawalSection/CryptoWithdrawalSection';
import { AddCryptoFormComponent } from './AddCryptoFormComponent/AddCryptoFormComponent';
import { type IAccount } from '../../../redux/reducers/accounts';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useGetWithdrawalTypesQuery } from '../../../redux/api/withdrawalTypesApi';
import { type WithdrawalType } from '../../../redux/reducers/withdrawalTypes';
import { useSnackbar } from '../../../components';
import { handleError } from '../../../helpers';
import style from './WithdrawalComponent.module.css';

interface InterfaceWithdrawalComponent {
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

export type TypeSubHeaderWithdrawalsOptions = 'Crypto' | 'Card' | 'Bank'

const WithdrawalNodes = {
  Card: CardWithdrawSection,
  Bank: BankWithdrawSection,
  Crypto: CryptoWithdrawSection
};
const WithdrawalAddMethodsNodes = {
  Card: AddCardFormComponent,
  Bank: AddBankFormComponent,
  Crypto: AddCryptoFormComponent
}

type OptionsType = Array<{ ticker: TypeSubHeaderWithdrawalsOptions }>

export const WithdrawalComponent: FC<InterfaceWithdrawalComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();

  const [withdrawalOptions, setWithdrawalOptions] = useState<OptionsType>([]);

  const [selectedAsset, setSelectedAsset] = useState<TypeSubHeaderWithdrawalsOptions | null>(null);
  const [step, setStep] = useState<number>(1);
  const [addWithdrawalMethod, setAddWithdrawalMethod] = useState(false);
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const { handleOpen } = useSnackbar();

  const {
    data: withdrawalData,
    error: withdrawalError,
    isLoading: isWithdrawalLoading
  } = useGetWithdrawalTypesQuery({});

  if (withdrawalError !== undefined) {
    handleError({
      isError: true,
      error: 'Some error happened',
      skipToast: true
    }, handleOpen)
  }

  useEffect(() => {
    const isDataLoadedSuccess = withdrawalData !== undefined && withdrawalData.success === true;
    const isWithdrawalSuccessful = withdrawalError === undefined;

    if (isDataLoadedSuccess && isWithdrawalSuccessful && !isWithdrawalLoading) {
      const options: OptionsType = withdrawalData.data
        .map(({ name }: WithdrawalType) => ({ ticker: name }));

      setWithdrawalOptions(options)
    }
  }, [withdrawalData, withdrawalError, isWithdrawalLoading]);

  useEffect(() => {
    const isAssetNotSelected = selectedAsset === null;
    const hasWithdrawalOptions = Array.isArray(withdrawalOptions) && withdrawalOptions.length > 0;

    if (isAssetNotSelected && hasWithdrawalOptions) {
      setSelectedAsset(withdrawalOptions[0].ticker);
    }
  }, [withdrawalOptions, selectedAsset]);

  const isMobile = screenWidth <= 599;

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  const renderSelectedWithdrawalForm = (): ReactElement => {
    if (selectedAsset !== null) {
      const Component = WithdrawalNodes[selectedAsset];
      const props = { setOpen, step, setStep, setAddWithdrawalMethod, selectedAccount };

      return <Component {...props} />;
    }

    return <>No Data</>;
  };

  const renderSelectedFormComponent = (): ReactElement => {
    if (selectedAsset !== null) {
      const Component = WithdrawalAddMethodsNodes[selectedAsset];
      const props = { setAddWithdrawalMethod };

      return <Component {...props} />;
    }

    return <>No Data</>;
  }

  return (
    <div className={ style.mainWrapper }>
      { addWithdrawalMethod
        ? <AddWithdrawalMethodSection setOpen={ setOpen } setAddWithdrawalMethod={ setAddWithdrawalMethod } selectedAccount={selectedAccount}>
            { renderSelectedFormComponent() }
          </AddWithdrawalMethodSection >
        : <>
        <WithdrawalHeaderComponent setOpen={ setOpen } title={`${t('labels.withdrawal')} ${selectedAccount?.cfdAccountCurrency.symbol ?? ''}`}/>
        <div className={ style.bodywrapper } >
          { step === 1 && selectedAsset !== null
            ? <WithdrawalSubHeaderComponent options={ withdrawalOptions } selectedOptions={ selectedAsset } setSelectedOptions={ setSelectedAsset }/>
            : null
          }
          { renderSelectedWithdrawalForm() }
        </div>
        </>}
    </div>
  )
}
