import React, { type FC, useState, useEffect, useMemo, type MouseEvent, type Dispatch, type SetStateAction, useRef, useLayoutEffect } from 'react';
import { socket } from '../../../../web/socket';
import { t } from 'i18next';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { type SvgIconTypeMap } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { type OverridableComponent } from '@mui/material/OverridableComponent';
import { type IAssetSocketData } from '../../../../interfaces';
import { DividerComponent, SearchInputComponent, ChipsComponent } from '../../../../components';
import { CoinIcon } from '../../../../components/icons/CoinsIcon';
import { EuroIcon } from '../../../../components/icons/EuroIcon';
import { ChartIcon } from '../../../../components/icons/ChartIcon';
import { PieChartIcon } from '../../../../components/icons/PieChartIcon';
import { FireIcon } from '../../../../components/icons/FireIcon';
import { MetalsIcon } from '../../../../components/icons/MetalsIcon';
// import { SortIcon } from '../../../../components/icons/SortIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getMarketData, searchByAssetName } from '../../../../redux/reducers/cfd';
import { getIconByName, TradeElementHeight } from '../../helper';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { setTradingMode } from '../../../../redux/reducers/tradingMode';
import styles from './Market.module.css';

interface IMarket {
  maxHeight?: number
  uniqueId: number
  isMarketShown?: boolean
  hideHeader?: boolean
  setExpanded?: (el: boolean) => void
  setIsNewsMobileShow?: Dispatch<SetStateAction<boolean>>
}

interface IWatchAsset {
  label: string
  symbol: string
  brokerSymbol: string
  precision: number
  _id: string
}

const iconsStyles = {
  width: '18px !important',
  minWidth: '18px !important',
  height: '18px !important',
  minHeight: '18px !important',
  color: 'var(--CFD-theme-System-Primary) !important'
}

const WatchListIcon = styled(StarIcon)(iconsStyles);
const CryptoIcon: OverridableComponent<SvgIconTypeMap> = () => (<CoinIcon width={18} height={18} color="var(--CFD-theme-System-Primary)" />);
const ForexIcon: OverridableComponent<SvgIconTypeMap> = () => (<EuroIcon width={18} height={18} color="var(--CFD-theme-System-Primary)" />);
const StocksIcon: OverridableComponent<SvgIconTypeMap> = () => (<ChartIcon width={18} height={18} color="var(--CFD-theme-System-Primary)" />);
const IndicesIcon: OverridableComponent<SvgIconTypeMap> = () => (<PieChartIcon width={18} height={18} color="var(--CFD-theme-System-Primary)" />);
const CommodityIcon: OverridableComponent<SvgIconTypeMap> = () => (<FireIcon width={18} height={18} color="var(--CFD-theme-System-Primary)" />);
const MetalIcon: OverridableComponent<SvgIconTypeMap> = () => (<MetalsIcon width={18} height={18} color="var(--CFD-theme-System-Primary)" />);

const marketToImg: Record<string, OverridableComponent<SvgIconTypeMap>> = {
  crypto: CryptoIcon,
  forex: ForexIcon,
  stocks: StocksIcon,
  indices: IndicesIcon,
  commodities: CommodityIcon,
  metals: MetalIcon
}

export const Market: FC<IMarket> = ({ maxHeight, uniqueId, isMarketShown, hideHeader = false, setExpanded, setIsNewsMobileShow }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [screenWidth] = useWindowSize();
  const divChipsRef = useRef<HTMLDivElement>(null);
  // const [sortedBy, setSortedBy] = useState('name');
  const [search, setSearch] = useState<string | null>(null)
  const [chipsHeight, setChipsHeight] = useState<number>(0);
  const [selectedMarket, setSelectedMarket] = useState('forex');
  const [watchList, setWatchList] = useState<IWatchAsset[]>([]);
  const [assetData, setAssetData] = useState<IAssetSocketData[]>([]);
  const marketData = useAppSelector((state) => state.cfd.marketData);
  const { tradingMode } = useAppSelector((state) => state.tradingMode);
  const { _id: userId } = useAppSelector((state) => state.user);

  const isMobile = screenWidth <= 1280;

  useEffect(() => {
    const recieveCFDDataEvent = `recieveCFDData&${uniqueId}`;

    socket.on(recieveCFDDataEvent, async ({ success, prices = [] }: { success: boolean, prices: IAssetSocketData[] }) => {
      if (success) {
        setAssetData((prev) => {
          const nextMarketPrices = [...(prev ?? [])];

          for (const price of prices) {
            if (price === undefined || Object.keys(price).length === 0) continue;

            const index = nextMarketPrices.findIndex((np) => np.symbol === price.symbol);

            if (index !== -1) {
              nextMarketPrices.splice(index, 1);
            }

            nextMarketPrices.push(price);
          }

          return nextMarketPrices;
        });
      }
    });

    // return () => {
    //   setTimeout(() => {
    //     setAssetData([]);
    //   }, 1000)
    // }
  }, [userId, uniqueId]);

  useLayoutEffect(() => {
    dispatch(getMarketData());

    const storedList = localStorage.getItem('watch-list') ?? null;
    if (storedList !== null) {
      const list = JSON.parse(storedList);
      setWatchList(list as IWatchAsset[]);
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      let chipsHeight = 0;
      for (const entry of entries) {
        chipsHeight = entry.contentRect.height + TradeElementHeight.ChipsPaddingTopBottom;
      }

      setChipsHeight(chipsHeight);
    });

    if (divChipsRef.current != null) {
      observer.observe(divChipsRef.current);
    }

    return () => {
      if (divChipsRef.current != null) {
        observer.unobserve(divChipsRef.current);
      }
    };
  }, [isMarketShown]);

  useEffect(() => {
    localStorage.setItem('watch-list', JSON.stringify(watchList))
  }, [watchList]);

  const searchAssetDelay = (value: string): void => {
    dispatch(searchByAssetName(value));
  };

  useDebounce({ value: search, delay: 300, action: searchAssetDelay });

  const addToWatchList = (e: React.MouseEvent<HTMLElement>, data: IWatchAsset): void => {
    e.stopPropagation();
    setWatchList((prev) => ([...prev, data]));
  }

  const removeFromWatchList = (e: React.MouseEvent<HTMLElement>, id: string): void => {
    e.stopPropagation();
    setWatchList((prev) => (prev.filter((item) => item._id !== id)));
  }

  const isAssetSelected = (id: string): boolean => {
    let isSelected = false;

    watchList.forEach((item) => {
      if (item._id === id) isSelected = true;
    });

    return isSelected;
  }

  const btnHandler = (event: MouseEvent<HTMLButtonElement>, symbol: string): void => {
    if (isMobile) dispatch(setTradingMode(true));
    if (isMobile && setExpanded !== undefined) setExpanded(false);
    if (isMobile && setIsNewsMobileShow !== undefined) setIsNewsMobileShow(false);
    navigate(`/trade?asset=${symbol}`);
  }

  useEffect(() => {
    if (search === null || search.length === 0) return;
    if (marketData.length > 0) setSelectedMarket(marketData[0]?.name);
  }, [search, marketData])

  const ChipsRow = useMemo(() => {
    return (
      <>
        <ChipsComponent
          label="Watch List"
          icon={WatchListIcon}
          onClick={() => { setSelectedMarket('watch'); }}
          isSelected={selectedMarket === 'watch'}
        />
        {
          marketData.map((market) => (
            <ChipsComponent
              key={market._id}
              label={t(`trading_page.market.labels.${market.name}`)}
              onClick={() => { setSelectedMarket(market.name); }}
              isSelected={selectedMarket === market.name}
              icon={marketToImg[market.name]}
            />
          ))
        }
      </>
    )
  }, [marketData, selectedMarket]);

  return (
    <>
     {
      (isMarketShown ?? false) && (
        <div
          className={styles.marketContainer}
          style={{
            height: !isMobile ? `${maxHeight}px` : '100%'
          }}
        >
            { !hideHeader && <div className={styles.marketContainerHeader}>
                <span>{t('trading_page.labels.market')}</span>
            </div> }
            <DividerComponent />
            <div className={styles.searchContainer}>
              <SearchInputComponent
                value={search}
                placeholder='Search'
                setValue={(value) => { setSearch(value); }}
              />
            </div>
            <div className={styles.chipsContainer} ref={divChipsRef}>
              {ChipsRow}
            </div>
            <div
              className={styles.marketTable}
              style={{
                height: (tradingMode && isMobile)
                  ? `calc(100% - ${chipsHeight + TradeElementHeight.MarketHeader + TradeElementHeight.MarketSearch - TradeElementHeight.TradingModeLabel}px)`
                  : `calc(100% - ${chipsHeight + TradeElementHeight.MarketHeader + TradeElementHeight.MarketSearch}px)`
              }}
            >
                <div className={styles.marketTableHeader}>
                    {/* <span
                        style={{ color: sortedBy === 'name' ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-Outline)' }}
                        onClick={() => { setSortedBy('name'); }}
                    >
                        Name
                        <SortIcon width={16} height={16} color={sortedBy === 'name' ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-Outline)'} />
                    </span>
                    <span
                        style={{ color: sortedBy === 'price' ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-Outline)' }}
                        onClick={() => { setSortedBy('price'); }}
                    >
                        Price
                        <SortIcon width={16} height={16} color={sortedBy === 'price' ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-Outline)'} />
                    </span> */}
                </div>
                {
                   selectedMarket !== 'watch'
                     ? (
                    <ul
                      className={styles.marketTableList}
                    >
                       {
                            marketData.map((market) => (
                                <React.Fragment key={`asset-${market._id}`}>
                                {
                                    (market.name === selectedMarket && market.name !== 'watch') && (
                                        <React.Fragment key={`asset-${market.name}-${market._id}`}>
                                            {
                                                market.settings.map((setting) => {
                                                  const ticker = assetData.find((ticker) => ticker.symbol === setting.brokerSymbol);

                                                  if (ticker === undefined) return null;
                                                  if (ticker.marketHolidays) {
                                                    return (<div key={setting._id} className={styles.marketTableItem}>
                                                      <div className={styles.marketTableItemAsset}>
                                                            {
                                                                isAssetSelected(setting._id)
                                                                  ? (
                                                                    <div className={styles.addToWatchList} onClick={(e) => { removeFromWatchList(e, setting._id); }}>
                                                                       <StarIcon sx={{ fontSize: 24, color: 'var(--CFD-theme-System-Tertiary)' }} />
                                                                    </div>
                                                                    )
                                                                  : (
                                                                    <div
                                                                    className={styles.addToWatchList}
                                                                    onClick={(e) => {
                                                                      addToWatchList(e, {
                                                                        label: setting.label,
                                                                        _id: setting._id,
                                                                        symbol: setting.symbol,
                                                                        brokerSymbol: setting.brokerSymbol,
                                                                        precision: setting.precision
                                                                      });
                                                                    }}>
                                                                        <StarBorderIcon sx={{ fontSize: 24, color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />
                                                                    </div>
                                                                    )
                                                            }
                                                            <img src={getIconByName(setting.label)} className={ styles.imgSize } alt={setting.label} />

                                                            <span style={{ marginLeft: '12px' }}>{setting.label}</span>
                                                        </div>
                                                        <div className={styles.marketTableItemPrices}>
                                                          <span>{t('labels.closed')}</span>
                                                        </div>
                                                    </div>)
                                                  }

                                                  return (
                                                    <button className={ styles.btnAsset } key={setting._id} onClick={(e) => { btnHandler(e, setting.symbol); } }>
                                                      <div className={styles.marketTableItemAsset}>
                                                          {
                                                              isAssetSelected(setting._id)
                                                                ? (
                                                                  <div className={styles.addToWatchList} onClick={(e) => { removeFromWatchList(e, setting._id); }}>
                                                                      <StarIcon sx={{ fontSize: 24, color: 'var(--CFD-theme-System-Tertiary)' }} />
                                                                  </div>
                                                                  )
                                                                : (
                                                                  <div
                                                                  className={styles.addToWatchList}
                                                                  onClick={(e) => {
                                                                    addToWatchList(e, {
                                                                      label: setting.label,
                                                                      _id: setting._id,
                                                                      symbol: setting.symbol,
                                                                      brokerSymbol: setting.brokerSymbol,
                                                                      precision: setting.precision
                                                                    });
                                                                  }}>
                                                                      <StarBorderIcon sx={{ fontSize: 24, color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />
                                                                  </div>
                                                                  )
                                                          }
                                                          <img src={getIconByName(setting.label)} className={ styles.imgSize } alt={setting.label} />

                                                          <span style={{ marginLeft: '12px' }}>{setting.label}</span>
                                                      </div>
                                                      <div className={styles.marketTableItemPrices}>
                                                          <span>{Number(ticker.bid).toFixed(setting.precision)}</span>
                                                          <span
                                                            style={{ color: ticker.price_change > 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }}>
                                                            {`${Number(ticker.price_change).toFixed(setting.precision)}%`}
                                                          </span>
                                                      </div>
                                                    </button>
                                                  )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                }
                                </React.Fragment>
                            ))
                        }
                    </ul>
                       )
                     : (
                    <ul
                      className={styles.marketTableList}
                    >
                        {
                            watchList.map((asset) => {
                              const ticker = assetData.find((ticker) => ticker.symbol === asset.brokerSymbol);

                              if (ticker === undefined) return null;

                              if (ticker.marketHolidays) {
                                return (
                                  <div key={asset._id} className={styles.marketTableItem}>
                                    <div className={styles.marketTableItemAsset}>
                                        <div className={styles.addToWatchList} onClick={(e) => { removeFromWatchList(e, asset._id); }}>
                                            <StarIcon sx={{ fontSize: 24, color: 'var(--CFD-theme-System-Tertiary)' }} />
                                        </div>
                                        <img src={getIconByName(asset.label)} className={ styles.imgSize } alt={asset.label} />

                                        <span style={{ marginLeft: '12px' }}>{asset.label}</span>
                                    </div>
                                    <div className={styles.marketTableItemPrices}>
                                      <span>{t('labels.closed')}</span>
                                    </div>
                                  </div>
                                )
                              }

                              return (
                                <button className={ styles.btnAsset } key={asset._id} onClick={(e) => { btnHandler(e, asset.symbol); } }>
                                  <div className={styles.marketTableItemAsset}>
                                      <div className={styles.addToWatchList} onClick={(e) => { removeFromWatchList(e, asset._id); }}>
                                          <StarIcon sx={{ fontSize: 24, color: 'var(--CFD-theme-System-Tertiary)' }} />
                                      </div>
                                      <img src={getIconByName(asset.label)} className={ styles.imgSize } alt={asset.label} />

                                    <span style={{ marginLeft: '12px' }}>{asset.label}</span>
                                </div>
                                <div className={styles.marketTableItemPrices}>
                                  <span>{Number(ticker.bid).toFixed(asset.precision)}</span>
                                  <span
                                    style={{ color: ticker.price_change > 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }}>
                                    {`${Number(ticker.price_change).toFixed(asset.precision)}%`}
                                  </span>
                                </div>
                              </button>
                              )
                            })
                        }
                    </ul>
                       )
                }
            </div>
        </div>
      )
     }
    </>
  )
}
